.App {
  text-align: center;
}

.App-logo {
  height: auto;
  max-width: 800px;
  pointer-events: none;
}

@media screen and (max-width: 600px) {
  .App-logo {
    width: 80vmin;
  }
}

.standard-text {
  font-size: 1.1em;
  font-weight: 400;
  margin: 0.5em 0;
  color: darkblue;
  max-width: 80%;
}

.standard-text-green {
  font-size: 1.1em;
  font-weight: 400;
  margin: 0.5em 0;
  color: green;
}

.copy-button {
  background-color: #2e9bb9;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 0.5em 1em;
  font-size: 1em;
  cursor: pointer;
  margin: 1em 0;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 2s linear;
  }
}

.App-header {
  background-color: whitesmoke;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #2e9bb9;
}

@keyframes App-logo-spin {
  0% {
    transform: rotate(-3deg);
  }
  50% {
    transform: rotate(3deg);
  }
  100% {
    transform: rotate(-3deg);
  }
}
